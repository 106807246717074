<template>
  <textarea
      id="kind_editor"
      v-model="content"
      style="width: 100%; min-height: 600px"
  />
</template>

<script>
import "kindeditor/themes/default/default.css";
import "kindeditor/kindeditor-all-min.js";
import "kindeditor/lang/zh-CN.js";
import {getUploadConfigApi} from './api'

export default {
    name: "index",

    data() {
        return {
            editor: null,
            content: "",
            qiniuConfig: {}
        }
    },


    async mounted(){
        const res = await getUploadConfigApi()
        this.qiniuConfig = res.config

        this.initEditor();
    },

    methods: {
        initEditor(){
            const config = {
                uploadJson: this.qiniuConfig.qiniu_upload, // 上传图片的服务器端脚本URL，需要自己实现
                allowFileManager: false,
                afterChange: function() {
                    self.content = this.html();
                },
                afterUpload: function(url) {
                    console.log(url); // 在上传成功后的回调中可以获取到上传的图片URL
                },
                qiniu: this.qiniuConfig
            }

            console.log(config, '@@@')

            this.editor = window.KindEditor.create("#kind_editor", config);
        }
    }
}


</script>

<style scoped>

</style>
