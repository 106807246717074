export default {
  path: '/guideWebsite',
  name: 'GuideWebsite',
  component: () => import('@/views/guideWebsite/index.vue'),
  meta: { name: '网站向导', icon: 'el-icon-s-grid' },
  children: [
    // 城市区域
    {
      path: '/guideWebsite/area/list',
      name: 'AreaList',
      component: () => import('@/views/guideWebsite/area/list.vue'),
      meta: { name: '城市区域' }
    },
    {
      path: '/guideWebsite/area/addEdit/:parent_id?',
      name: 'AreaAdd',
      component: () => import('@/views/guideWebsite/area/addEdit.vue'),
      meta: { name: '新增区域', hidden: true, activeMenu: '/guideWebsite/area/list' }
    },
    {
      path: '/guideWebsite/area/addEdit/:menuId?',
      name: 'AreaEdit',
      component: () => import('@/views/guideWebsite/area/addEdit.vue'),
      meta: { name: '编辑区域', hidden: true, activeMenu: '/guideWebsite/area/list' }
    },
    // 网站栏目
    {
      path: '/guideWebsite/category/list',
      name: 'GuideWebsiteCategoryList',
      component: () => import('@/views/guideWebsite/site-category/list.vue'),
      meta: { name: '网站栏目' }
    },
    {
      path: '/guideWebsite/category/add',
      name: 'GuideWebsiteCategoryAdd',
      component: () => import('@/views/guideWebsite/site-category/addOrEdit.vue'),
      meta: { name: '新增栏目', hidden: true, activeMenu: '/guideWebsite/category/list' }
    },
    {
      path: '/guideWebsite/category/edit/:id?',
      name: 'GuideWebsiteCategoryEdit',
      component: () => import('@/views/guideWebsite/site-category/addOrEdit.vue'),
      meta: { name: '编辑栏目', hidden: true, activeMenu: '/guideWebsite/category/list' }
    },
    // 网站向导
    {
      path: '/guideWebsite/siteGuide/list',
      name: 'GuideWebsiteSiteGuideList',
      component: () => import('@/views/guideWebsite/site-guide/list.vue'),
      meta: { name: '网站向导' }
    },
    {
      path: '/guideWebsite/siteGuide/add',
      name: 'GuideWebsiteSiteGuideAdd',
      component: () => import('@/views/guideWebsite/site-guide/addOrEdit.vue'),
      meta: { name: '新增向导', hidden: true, activeMenu: '/guideWebsite/siteGuide/list' }
    },
    {
      path: '/guideWebsite/siteGuide/edit/:id?',
      name: 'GuideWebsiteSiteGuideEdit',
      component: () => import('@/views/guideWebsite/site-guide/addOrEdit.vue'),
      meta: { name: '编辑向导', hidden: true, activeMenu: '/guideWebsite/siteGuide/list' }
    },
    {
      path: '/guideWebsite/siteGuide/datav',
      name: 'GuideWebsiteSiteGuideDatav',
      component: () => import('@/views/guideWebsite/guide-datav/index.vue'),
      meta: { name: '网站分析' }
    },
  ]
}
