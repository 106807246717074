// 常量路由

import indexRouter from '@/router/modules/index'
import aboutRouter from '@/router/modules/about'
import evaluateRouter from '@/router/modules/evaluate'
import coursenotesRouter from '@/router/modules/coursenotes'
import feedbackRouter from '@/router/modules/feedback'
import courseRouter from '@/router/modules/course'
import memberLevelRouter from '@/router/modules/memberLevel'
import downloadRouter from '@/router/modules/download'
import userRouter from '@/router/modules/user'
import articleRouter from '@/router/modules/article'
import merchantRouter from '@/router/modules/merchant'
import operationRouter from '@/router/modules/operation'
import systemManageRouter from '@/router/modules/systemManage'
import powerManageRouter from '@/router/modules/powerManage'
import activityRouter from './modules/activity'
import marketRouter from './modules/market'
import employeeRouter from './modules/employee'
import jiaowuRouter from './modules/jiaowu'
import guideWebsiteRouter from './modules/guideWebsite'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: 'dashboard'
    },
    component: () => import('../views/home/index.vue')
  },

  // teacherRouter,

  // 首页
  indexRouter,

  // 课程管理
  articleRouter,

  // 精品课程
  courseRouter,

  // 下载专区
  downloadRouter,

  coursenotesRouter,

  // 关于我们
  aboutRouter,

  // 会员管理
  memberLevelRouter,
  // 网站向导
  guideWebsiteRouter,

  // evaluateRouter,
  // feedbackRouter,
  // userRouter,

  // 商户
  // merchantRouter,
  // 活动管理
  // activityRouter,
  // 营销管理
  // marketRouter,
  operationRouter,

  //employeeRouter,
  systemManageRouter

  // powerManageRouter
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  // {
  //   path: '/customer',
  //   name: 'Customer',
  //   component: () => import('@/views/customer/index.vue'),
  //   meta: {
  //     icon: 'el-icon-s-custom',
  //     name: '用户管理'
  //   },
  //   redirect: '/customer/list',
  //   children: [
  //     {
  //       path: '/customer/list',
  //       name: 'CustomerList',
  //       meta: {
  //         name: '用户列表',
  //         icon: ''
  //       },
  //       component: () => import('@/views/customer/list.vue')
  //     },
  //     // {
  //     //   path: '/customer/addRemark/:memberId',
  //     //   name: 'CustomerAddRemark',
  //     //   component: () => import('@/views/customer/addRemark.vue'),
  //     //   meta: { name: '添加备注', hidden: true }
  //     // },
  //     {
  //       path: '/customer/operationLog/:memberId?',
  //       name: 'CustomerRemarkList',
  //       component: () => import('@/views/customer/operationLog.vue'),
  //       meta: { name: '备注列表', hidden: true }
  //     },
  //     {
  //       path: '/customer/order/list/:id?',
  //       name: 'CustomerOrderList',
  //       component: () => import('@/views/customer/detail.vue'),
  //       meta: { name: '订单列表', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/order',
  //   name: 'Order',
  //   redirect: '/order/list',
  //   component: () => import('@/views/order/index.vue'),
  //   meta: { name: '订单管理', icon: 'el-icon-s-order' },
  //   children: [
  //     {
  //       path: '/order/list/',
  //       name: 'OrderList',
  //       component: () => import('@/views/order/list.vue'),
  //       meta: { name: '订单列表' }
  //     },
  //     // {
  //     //   path: '/order/addEdit/:id?',
  //     //   name: 'OrderAddEdit',
  //     //   component: () => import('@/views/order/addEdit.vue'),
  //     //   meta: { name: '新增、编辑', hidden: true }
  //     // },
  //     {
  //       path: '/order/detail/:id?',
  //       name: 'OrderDetail',
  //       component: () => import('@/views/order/detail.vue'),
  //       meta: { name: '账单详情', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/bill',
  //   name: 'Bill',
  //   redirect: '/bill/list',
  //   component: () => import('@/views/bill/index.vue'),
  //   meta: { name: '账单管理', icon: 'el-icon-coin' },
  //   children: [
  //     {
  //       path: '/bill/list/',
  //       name: 'BillList',
  //       component: () => import('@/views/bill/list.vue'),
  //       meta: { name: '账单列表' }
  //     },
  //     {
  //       path: '/bill/addEdit/:id?',
  //       name: 'BillAddEdit',
  //       component: () => import('@/views/bill/addEdit.vue'),
  //       meta: { name: '新增、编辑', hidden: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/finance',
  //   name: 'Finance',
  //   redirect: '/finance/list',
  //   component: () => import('@/views/finance/index.vue'),
  //   meta: { name: '财务管理', icon: 'el-icon-coin' },
  //   children: [
  //     {
  //       path: '/finance/list/',
  //       name: 'FinanceList',
  //       component: () => import('@/views/finance/list.vue'),
  //       meta: { name: '财务明细' }
  //     }
  //   ]
  // },
]
