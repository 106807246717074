const articleRouter = {
    path: '/article',
    name: 'Article',
    redirect: '/article/category/list',
    meta: {
        icon: 'el-icon-s-goods',
        name: '资料管理'
    },
    component: () => import('@/views/article/category/index.vue'),
    children: [
        {
            path: '/article/category/list',
            name: 'articleCategoryList',
            component: () => import('@/views/article/category/category-manage/index.vue'),
            meta: {
                name: '课程类目'
            }
        },
        {
            path: '/article/category/add',
            name: 'articleCategoryAdd',
            component: () => import('@/views/article/category/category-manage/addEdit.vue'),
            meta: {
                name: '创建分类',
                hidden: true
            }
        },
        {
            path: '/article/category/edit/:id?',
            name: 'articleCategoryEdit',
            component: () => import('@/views/article/category/category-manage/addEdit.vue'),
            meta: {
                name: '编辑分类',
                hidden: true
            }
        },
        {
            path: '/article/list',
            name: 'articleList',
            component: () => import('@/views/article/article-manage/index.vue'),
            meta: {
                name: '课程列表'
            }
        },
        {
            path: '/article/add',
            name: 'articleAdd',
            component: () => import('@/views/article/article-manage/addEdit.vue'),
            meta: {
                name: '创建课程',
                hidden: true
            }
        },
        {
            path: '/article/edit/:id?',
            name: 'articleEdit',
            component: () => import('@/views/article//article-manage/addEdit.vue'),
            meta: {
                name: '编辑课程',
                hidden: true
            }
        }
    ]
}

export default articleRouter
