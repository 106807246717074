const memberLevelRouter = {
    path: '/member',
    name: 'member',
    redirect: '/member/list',
    meta: {
        icon: 'el-icon-s-goods',
        name: '会员管理'
    },
    component: () => import('@/views/memberLevel/index.vue'),
    children: [
        {
            path: '/member/level/list',
            name: 'MemberLevelList',
            component: () => import('@/views/memberLevel/member-manage/index.vue'),
            meta: {
                name: '会员等级'
            }
        },
        {
            path: '/member/level/add',
            name: 'memberLevelAdd',
            component: () => import('@/views/memberLevel/member-manage/addEdit.vue'),
            meta: {
                name: '创建等级',
                hidden: true
            }
        },
        {
            path: '/member/level/edit/:id?',
            name: 'memberLevelEdit',
            component: () => import('@/views/memberLevel/member-manage/addEdit.vue'),
            meta: {
                name: '编辑等级',
                hidden: true
            }
        },


        {
            path: '/member/member-list',
            name: 'MemberList',
            component: () => import('@/views/member/member-list/list.vue'),
            meta: {
                name: '用户列表'
            }
        },
        {
            path: '/member/member-edit/:id?',
            name: 'MemberEdit',
            component: () => import('@/views/member/member-list/addEdit.vue'),
            meta: {name: '编辑用户', hidden: true}
        },
    ]
}

export default memberLevelRouter
