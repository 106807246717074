const aboutRouter = {
    path: '/about',
    name: 'About',
    component: () => import('@/views/operation/index.vue'),
    meta: {name: '关于我们', icon: 'el-icon-s-finance'},
    children: [
        {
            path: '/about/aboutUs',
            name: 'AboutUs',
            component: () => import('@/views/operation/aboutUs/list.vue'),
            meta: {name: '关于我们'}
        },

    ]
}

export default aboutRouter
