const indexRouter = {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/operation/index.vue'),
    meta: {name: '首页', icon: 'el-icon-s-finance'},
    children: [
        {
            path: '/index/ad',
            name: 'Ad',
            component: () => import('@/views/operation/ad/list.vue'),
            meta: {name: '导航栏'}
        },
        {
            path: '/index/decorate-banner',
            name: 'decorateBanner',
            component: () => import('@/views/operation/ad/decorate-banner.vue'),
            meta: {name: '首页banner'}
        },
        {
            path: '/index/index-product',
            name: 'indexProduct',
            component: () => import('@/views/operation/index-product/index.vue'),
            meta: {name: '产品介绍'}
        },
        {
            path: '/index/index-product/add',
            name: 'indexProductAdd',
            component: () => import('@/views/operation/index-product/addEdit.vue'),
            meta: {name: '新增产品介绍', hidden: true}
        },
        {
            path: '/index/index-product/edit/:id',
            name: 'indexProductEdit',
            component: () => import('@/views/operation/index-product/addEdit.vue'),
            meta: {name: '编辑产品介绍', hidden: true}
        },
        {
            path: '/notice/list',
            name: 'noticeList',
            component: () => import('@/views/article/notice-manage/index.vue'),
            meta: {
                name: '通知公告'
            }
        },
        {
            path: '/notice/add',
            name: 'noticeAdd',
            component: () => import('@/views/article/notice-manage/addEdit.vue'),
            meta: {
                name: '创建公告',
                hidden: true
            }
        },
    ]
}

export default indexRouter
