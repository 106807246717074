const systemManageRouter = {
    path: '/systemManage',
    name: 'SystemManage',
    redirect: '/systemManage/dictManage/list',
    component: () => import('@/views/systemManage/index.vue'),
    meta: {name: '系统设置', icon: 'el-icon-setting'},
    children: [
        // {
        //     path: '/systemManage/dictManage/list',
        //     name: 'DictManageList',
        //     component: () => import('@/views/systemManage/dictManage/index.vue'),
        //     meta: {name: '字典管理'}
        // },
        // {
        //     path: '/systemManage/dictManage/addOrEdit',
        //     name: 'dictManageAddOrEdit',
        //     component: () => import('@/views/systemManage/dictManage/detail-dict.vue'),
        //     meta: {hidden: true, name: '字典管理'}
        // },
        {
            path: '/systemManage/dictValueManage',
            name: 'DictValueManage',
            component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
            meta: {hidden: true, name: '字典值管理'}
        },
        {
            path: '/systemManage/dictValueManage/addOrEdit/:id?',
            name: 'DictValueAddOrEdit',
            component: () => import('@/views/systemManage/dictManage/detail-dict-value.vue'),
            meta: {hidden: true, name: '字典值管理'}
        },
        {
            path: '/systemManage/config/list',
            name: 'ConfigList',
            component: () => import('@/views/systemManage/config/list.vue'),
            meta: {name: '开发设置'}
        },
        // {
        //     path: '/systemManage/logManage/list',
        //     name: 'LogManageList',
        //     component: () => import('@/views/systemManage/logManage/list.vue'),
        //     meta: {name: '操作日志'}
        // }
    ]
}

export default systemManageRouter
