import { http } from '@/http/axios.js'

export function loginApi(data) {
    return http({
        url: '/company/basic/passport/login',
        method: 'post',
        data
    })
}


export function logoutApi() {
    return http({
        url: '/company/basic/passport/logout',
        method: 'post',
    })
}
