const operationRouter = {
    path: '/operation',
    name: 'Operation',
    component: () => import('@/views/operation/index.vue'),
    meta: {name: '运营管理', icon: 'el-icon-s-finance'},
    children: [

        {
            path: '/operation/websitConfig',
            name: 'WebsitConfig',
            component: () => import('@/views/operation/web-site/index.vue'),
            meta: {name: '站点设置'}
        },
        // {
        //     path: '/operation/contract',
        //     name: 'Contract',
        //     component: () => import('@/views/operation/contract/list.vue'),
        //     meta: {name: '协议合同'}
        // },
        // {
        //     path: '/operation/sharingConfig',
        //     name: 'SharingConfig',
        //     component: () => import('@/views/operation/sharing/list.vue'),
        //     meta: {name: '分享配置'}
        // },

        {
            path: '/operation/feedback/list',
            name: 'FeedbackList',
            component: () => import('@/views/feedback/feedback-manage/index.vue'),
            meta: {
                name: '反馈列表'
            }
        },
        {
            path: '/operation/feedback/add',
            name: 'FeedbackAdd',
            component: () => import('@/views/feedback/feedback-manage/addEdit.vue'),
            meta: {
                name: '新增反馈',
                hidden: true
            }
        },
        {
            path: '/operation/feedback/edit/:id?',
            name: 'FeedbackEdit',
            component: () => import('@/views/feedback/feedback-manage/addEdit.vue'),
            meta: {
                name: '编辑反馈',
                hidden: true
            }
        }
    ]
}

export default operationRouter
