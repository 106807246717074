const courseRouter = {
    path: '/course',
    name: 'Course',
    redirect: '/course/list',
    meta: {
        icon: 'el-icon-s-goods',
        name: '精品课程'
    },
    component: () => import('@/views/course/index.vue'),
    children: [
        {
            path: '/course/list',
            name: 'CourseHdList',
            component: () => import('@/views/course/course-manage/index.vue'),
            meta: {
                name: '课程列表'
            }
        },
        {
            path: '/course/add',
            name: 'CourseAdd',
            component: () => import('@/views/course/course-manage/addEdit.vue'),
            meta: {
                name: '创建课程',
                hidden: true
            }
        },
        {
            path: '/course/edit/:id?',
            name: 'CourseEdit',
            component: () => import('@/views/course/course-manage/addEdit.vue'),
            meta: {
                name: '编辑课程',
                hidden: true
            }
        },

        {
            path: '/course/evaluate/list',
            name: 'EvaluateList',
            component: () => import('@/views/evaluate/evaluate-manage/index.vue'),
            meta: {
                name: '评价列表'
            }
        },
        {
            path: '/course/evaluate/add',
            name: 'EvaluateAdd',
            component: () => import('@/views/evaluate/evaluate-manage/addEdit.vue'),
            meta: {
                name: '新增评价',
                hidden: true
            }
        },
        {
            path: '/course/evaluate/edit/:id?',
            name: 'EvaluateEdit',
            component: () => import('@/views/evaluate/evaluate-manage/addEdit.vue'),
            meta: {
                name: '编辑评价',
                hidden: true
            }
        }
    ]
}

export default courseRouter
