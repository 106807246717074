<template>
    <div>
        <VueUeditorWrap v-model="content" :config="myConfig"></VueUeditorWrap>
    </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
    name: "ueditor",
    components: {VueUeditorWrap},

    props: {
        content: {
            type: String,
            default: ''
        },
    },

    watch: {
        content: function (newVal) {
            this.$emit('changeContent', newVal)
        }
    },

    data() {
        return {
            ueContent: '',
            myConfig: {
                // 编辑器不自动被内容撑高
                autoHeightEnabled: true,
                // 初始容器高度
                initialFrameHeight: 600,
                // 初始容器宽度
                initialFrameWidth: "150%",
                // UEditor 资源文件的存放路径，
                UEDITOR_HOME_URL: "/ueditor/",
                imagePopup: false,
                imageScaleEnabled: false,
                elementPathEnabled: false,
                autoFloatEnabled: false,
                allowDivTransToP: false,
                zIndex: 1,
                // 允许的最大字符数
                maximumWords: 100000,
                serverUrl: "http://127.0.0.1:9503/common/baiduEditor/uploader"
            },
        }
        //     ueConfig: {
        //         toolbars: [
        //             [
        //                 'undo', //撤销
        //                 'bold', //加粗
        //                 'indent', //首行缩进
        //                 'italic', //斜体
        //                 'underline', //下划线
        //                 'strikethrough', //删除线
        //                 'subscript', //下标
        //                 'fontborder', //字符边框
        //                 'superscript', //上标
        //                 'formatmatch', //格式刷
        //                 'fontfamily', //字体
        //                 'fontsize', //字号
        //                 'justifyleft', //居左对齐
        //                 'justifycenter', //居中对齐
        //                 'justifyright', //居右对齐
        //                 'justifyjustify', //两端对齐
        //                 'insertorderedlist', //有序列表
        //                 'insertunorderedlist', //无序列表
        //                 'lineheight',//行间距
        //             ]
        //         ],
        //         'fontfamily': [
        //             {label: '', name: 'songti', val: '宋体,SimSun'},
        //             {label: '仿宋', name: 'fangsong', val: '仿宋,FangSong'},
        //             {label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong'},
        //             {label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai'},
        //             {label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei'},
        //             {label: '', name: 'heiti', val: '黑体, SimHei'},
        //             {label: '', name: 'lishu', val: '隶书, SimLi'},
        //             {label: '', name: 'andaleMono', val: 'andale mono'},
        //             {label: '', name: 'arial', val: 'arial, helvetica,sans-serif'},
        //             {label: '', name: 'arialBlack', val: 'arial black,avant garde'},
        //             {label: '', name: 'comicSansMs', val: 'comic sans ms'},
        //             {label: '', name: 'impact', val: 'impact,chicago'},
        //             {label: '', name: 'timesNewRoman', val: 'times new roman'}
        //         ],
        //         UEDITOR_HOME_URL: "/ueditor",
        //         // 初始容器高度
        //         initialFrameHeight: 300,
        //         // 初始容器宽度
        //         initialFrameWidth: '100%',
        //         // 上传文件接口
        //         enableAutoSave: false,
        //         elementPathEnable: false,
        //         wordCount: false,
        //     },
        // }
    }
}
</script>

<style scoped>

</style>
