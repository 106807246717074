export default {
    getOptions() {
        return {
            namespaced: true,
            state: {
                pageSize: 10,
                totalPage: 0,
                currentPage: 1,
                totalRow: 0,
                search:{}
            },
            actions: {
            },
            mutations: {
                SET_CURRENT_PAGE(state, value) {
                    state.currentPage = value
                },

                SET_SEARCH(state, value) {
                    state.search = value
                },

                SET_PAGE_ITEM(state, pageOptions) {
                    console.log('SET_PAGE_ITEM', pageOptions);
                    let {pageSize, totalPage, totalRow, currentPage} = pageOptions

                    state.totalPage = totalPage
                    state.totalRow = totalRow
                    state.currentPage = currentPage
                    state.pageSize = pageSize
                }
            },
            getters: {
                dataRows(state) {
                    return state.totalRow
                }
            }
        }
    }
}
