const coursenotesRouter = {
    path: '/coursenotes',
    name: 'Coursenotes',
    redirect: '/coursenotes/list',
    meta: {
        icon: 'el-icon-s-goods',
        name: '课程资料'
    },
    component: () => import('@/views/coursenotes/index.vue'),
    children: [
        {
            path: '/coursenotes/list',
            name: 'CoursenotesList',
            component: () => import('@/views/coursenotes/coursenotes-manage/index.vue'),
            meta: {
                name: '课程资料'
            }
        },
        {
            path: '/coursenotes/add',
            name: 'CoursenotesAdd',
            component: () => import('@/views/coursenotes/coursenotes-manage/addEdit.vue'),
            meta: {
                name: '创建课程资料',
                hidden: true
            }
        },
        {
            path: '/coursenotes/edit/:id?',
            name: 'CoursenotesEdit',
            component: () => import('@/views/coursenotes/coursenotes-manage/addEdit.vue'),
            meta: {
                name: '编辑课程资料',
                hidden: true
            }
        }
    ]
}

export default coursenotesRouter
