const jiaowuRouter = {
    path: '/jiaowu',
    name: 'Jiaowu',
    redirect: '/jiaowu/student/list',
    meta: {
        icon: 'el-icon-s-goods',
        name: '教务管理'
    },
    component: () => import('@/views/jiaowu/index.vue'),
    children: [
        {
            path: '/jiaowu/student/list',
            name: 'JiaowuStudentList',
            component: () => import('@/views/jiaowu/student-manage/index.vue'),
            meta: {
                name: '学员管理', type: 1
            }
        },
        {
            path: '/jiaowu/student/add',
            name: 'JiaowuStudentAdd',
            component: () => import('@/views/jiaowu/student-manage/addEdit.vue'),
            meta: {
                name: '创建学员',
                hidden: true
            }
        },
        {
            path: '/jiaowu/student/edit/:id?',
            name: 'JiaowuStudentEdit',
            component: () => import('@/views/jiaowu/student-manage/addEdit.vue'),
            meta: {
                name: '编辑学员',
                hidden: true
            }
        }
    ]
}

export default jiaowuRouter
